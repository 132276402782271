import gql from 'graphql-tag';

import { segmentationFields } from '../segmentation-fields-fragment';

const segmentationSectionFragment = gql`
  fragment segmentationSectionFields on SegmentationSection {
    segmentationFields: segmentation_fields {
      ${segmentationFields}
    }
  }
`;

export default segmentationSectionFragment;
