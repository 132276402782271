import { getConfig } from '@lib/prepr/config';
import { getDynamicPage } from '@lib/prepr/dynamic';
import ContentTemplate from '@templates/ContentTemplate/ContentTemplate';
import EventOverviewTemplate from '@templates/EventsOverviewTemplate/EventsOverviewTemplate';
import EventTemplate from '@templates/EventTemplate/EventTemplate';
import ExploreTemplate from '@templates/ExploreTemplate/ExploreTemplate';
import LocationOverviewTemplate from '@templates/LocationOverviewTemplate/LocationOverviewTemplate';
import LocationTemplate from '@templates/LocationTemplate/LocationTemplate';
import MapTemplate from '@templates/MapTemplate/MapTemplate';
import NewsOverviewTemplate from '@templates/NewsOverviewTemplate/NewsOverviewTemplate';
import NewsTemplate from '@templates/NewsTemplate/NewsTemplate';
import RoutesOverviewTemplate from '@templates/RoutesOverviewTemplate/RoutesOverviewTemplate';
import RouteTemplate from '@templates/RouteTemplate/RouteTemplate';
import SearchTemplate from '@templates/SearchTemplate/SearchTemplate';
import SharedListTemplate from '@templates/SharedListTemplate/SharedListTemplate';
import ZigzagCampaignTemplate from '@templates/ZigzagCampaignTemplate/ZigzagCampaignTemplate';
import { Locale } from '@type-declarations/locale';
import {
  ContentPage,
  EventOverviewPage,
  EventPage,
  ExplorePage,
  LocationOverviewPage,
  LocationPage,
  MainCategoryPage,
  MapPage,
  NewsOverviewPage,
  NewsPage,
  RouteOverviewPage,
  RoutePage,
  SearchPage,
  SharedListPage,
  SubCategoryPage,
  SubSubCategoryPage,
  ZigzagCampaignPage,
} from '@type-declarations/page';
import getHeroVariant from '@utils/getHeroVariant';

function Page({
  page,
}: {
  page:
    | LocationPage
    | ContentPage
    | LocationOverviewPage
    | EventPage
    | EventOverviewPage
    | NewsPage
    | NewsOverviewPage
    | RoutePage
    | RouteOverviewPage
    | ExplorePage
    | MainCategoryPage
    | SubCategoryPage
    | SubSubCategoryPage
    | MapPage
    | ZigzagCampaignPage
    | SearchPage
    | SharedListPage;
}) {
  switch (page.typename) {
    case 'ExplorePage':
      return <ExploreTemplate page={page} />;

    case 'LocationPage':
      return <LocationTemplate page={page} />;

    case 'LocationOverviewPage':
      return <LocationOverviewTemplate page={page} />;

    case 'EventOverviewPage':
      return <EventOverviewTemplate page={page} />;

    case 'NewsOverviewPage':
      return <NewsOverviewTemplate page={page} />;

    case 'NewsPage':
      return <NewsTemplate page={page} />;

    case 'EventPage':
      return <EventTemplate page={page} />;

    case 'RouteOverviewPage':
      return <RoutesOverviewTemplate page={page} />;

    case 'RoutePage':
      return <RouteTemplate page={page} />;

    case 'ContentPage':
      return <ContentTemplate page={page} />;

    case 'SearchPage':
      return <SearchTemplate page={page} />;

    case 'MapPage':
      return <MapTemplate page={page} />;

    case 'ZigzagCampaignPage':
      return <ZigzagCampaignTemplate page={page} />;

    case 'SharedListPage':
      return <SharedListTemplate page={page} />;

    case 'MainCategory':
    case 'SubCategory':
    case 'SubSubCategory':
      return <ContentTemplate page={page} />;

    default:
      /* TODO: return homepage here */
      return <h1>Pagina niet gevonden</h1>;
  }
}

export async function getServerSideProps({
  req,
  locale,
  params,
  preview = false,
  query,
}: {
  req: { cookies: { __prepr_uid: string } };
  locale: Locale;
  params: { slug: string[] };
  preview: boolean;
  query: {
    page?: string;
    tags?: string;
    accessibility?: string;
    title?: string;
    period?: string;
    companion?: string;
    transport?: string;
    weather?: string;
    secret?: string;
  };
}) {
  const { __prepr_uid: customerId = '' } = req.cookies;
  const { slug } = params;
  const [data, config] = await Promise.all([
    getDynamicPage({
      customerId,
      slug,
      locale,
      query,
      preview,
    }),
    getConfig({ locale, preview }),
  ]);

  if (!data.page) {
    console.warn('Page not found');
    return {
      notFound: true,
    };
  }

  if ('pageActivated' in data.page && data.page.pageActivated === false) {
    console.warn('Page not activated');

    return {
      notFound: true,
    };
  }

  return {
    props: {
      customerId,
      locale,
      preview,
      page: {
        ...data.page,
        exploreTags: data.exploreTags || [],
        categories: data.categories || [],
        items: data.items || [],
        total: data.total || 0,
        additionalSectionData: data.additionalSectionData || {},
        heroVariant: getHeroVariant(data.page.typename),
      },
      config,
    },
  };
}

export default Page;
