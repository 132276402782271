import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';
import RichText from '@atoms/RichText/RichText';
import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import PinIcon from '@svg/route-pin.svg';
import { StoreModifier } from '@type-declarations/modifier';
import { RoutePoint } from '@type-declarations/prepr';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './RouteItem.module.scss';

interface Props {
  stop: RoutePoint;
  index: number;
  modifier?: StoreModifier;
}

export default function RouteItem({ stop, index, modifier }: Props) {
  const {
    store: { locale },
  } = useStore();

  const [isOpen, setIsOpen] = useState(index === 0);
  const { title, address, image, slug } = stop.location[0];
  const icon =
    stop.location[0].category?.[0]?.icon ||
    stop.location[0].category?.[0]?.category?.[0]?.icon ||
    'MAP_PIN';

  const [itemHeight, setItemHeight] = useState<string>('0');
  const contentRef = useRef<HTMLDivElement>(null);
  const { viewLocation } = useTranslations();

  useEffect(() => {
    setItemHeight(`${contentRef?.current?.offsetHeight || 0}px`);
  }, [isOpen]);

  const style = { '--item-height': itemHeight } as React.CSSProperties;

  const handleClick = () => {
    setIsOpen(state => !state);
  };

  const img = stop.image || image;
  const itemId = `stop-${stop.id}`;

  return (
    <li className={styles.item}>
      <PinIcon className={styles.pin} />
      <Icon className={styles.icon} icon={icon} />
      <span className={styles.index}>{index + 1}</span>
      <button
        type="button"
        className={styles.itemButton}
        aria-expanded={isOpen}
        aria-controls={itemId}
        onClick={handleClick}
      >
        <span className={clsx(styles.itemTitle, 'u-typography-paragraph-lg')}>
          {title}
        </span>
        <span className="u-typography-paragraph-md">
          {[
            address.street,
            (address.streetNumber || '') + (address.streetNumberSuffix || ''),
            address.city,
          ]
            .filter(x => !!x)
            .join(' ')}
        </span>

        <Icon icon="NAV_ARROW_DOWN" className={styles.chevron} />
      </button>
      <div id={itemId} style={style} className={styles.panel}>
        <CSSTransition
          in={!!isOpen}
          timeout={300}
          unmountOnExit
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          <div className={styles.wrapper}>
            <div className={styles.content} ref={contentRef}>
              {stop.instructions && <RichText text={stop.instructions} />}
              <Button
                href={
                  modifier === 'inStore'
                    ? `/store/locations/${slug}`
                    : `${ROUTES.LocationOverviewPage[locale]}/${slug}`
                }
                size="small"
                modifier={modifier}
                label={viewLocation}
                target={modifier === 'inStore' ? '_self' : '_blank'}
                className={styles.cta}
                icon="NAV_ARROW_RIGHT"
              />
              {img && (
                <div className={styles.img}>
                  <Image
                    src={img.url}
                    placeholder="blur"
                    blurDataURL={img.placeholder}
                    objectFit="cover"
                    objectPosition="center"
                    layout="fill"
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      </div>
    </li>
  );
}
