export const segmentationFields = `
  id: _id
  title
  text
  icon
  items {
    ...locationItem
    ...eventItem
    ...contentItem
    ...routeItem
    ...newsItem
  }
`;
