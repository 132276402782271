import gql from 'graphql-tag';

import accessibilitySectionFragment from './sections/accessibility-section-fragment';
import contentCarouselSectionFragment from './sections/content-carousel-section-fragment';
import ctaButtonsSectionFragment from './sections/cta-buttons-section-fragment';
import eventCarouselSectionFragment from './sections/event-carousel-section-fragment';
import eventRegistrationSectionFragment from './sections/event-registration-section-fragment';
import faqSectionFragment from './sections/faq-section-fragment';
import iframeSectionFragment from './sections/iframe-section-fragment';
import imageSectionFragment from './sections/image-section-fragment';
import imageSliderSectionFragment from './sections/image-slider-section-fragment';
import languageFormSectionFragment from './sections/language-form-section-fragment';
import newsletterSectionFragment from './sections/newsletter-section-fragment';
import quoteSectionFragment from './sections/quote-section-fragment';
import segmentationSectionFragment from './sections/segmentation-fragment';
import textImageSectionFragment from './sections/text-image-section-fragment';
import textSectionFragment from './sections/text-section-fragment';
import textVideoSectionFragment from './sections/text-video-section-fragment';
import videoSectionFragment from './sections/video-section-fragment';

export const sectionsFields = `
  sections {
    ... on Component {
      typename: __typename
      id: _id
    }

    ... on TextSection {
      ...textSectionFields
    }
    ... on ImageSection {
      ...imageSectionFields
    }
    ... on VideoSection {
      ...videoSectionFields
    }
    ... on TextImageSection {
      ...textImageSectionFields
    }
    ... on TextVideoSection {
      ...textVideoSectionFields
    }
    ... on CtaButtonsSection {
      ...ctaButtonsSectionFields
    }
    ... on AccessibilitySection {
      ...accessibilitySectionFields
    }
    ... on ImageSliderSection {
      ...imageSliderSectionFields
    }
    ... on FaqSection {
      ...faqSectionFields
    }
    ... on QuoteSection {
      ...quoteSectionFields
    }
    ... on EventRegistrationSection {
      ...eventRegistrationFields
    }
    ... on ContentCarouselSection {
      ...contentCarouselSectionFields
    }
    ... on ExploreFormSection {
      ...languageFormSectionFields
    }
    ... on EventCarouselSection {
      ...eventCarouselSectionFields
    }
    ... on NewsletterSection {
      ...newsletterSectionFields
    }
    ... on IframeSection {
      ...iframeSectionFields
    }
    ... on SegmentationSection {
      ...segmentationSectionFields
    }
  }
`;

export const heroContentFields = `
  typename: __typename
  
  ... on CtaButtonsSection {
    ...ctaButtonsSectionFields
  }

  ... on ContentCarouselSection {
    ...contentCarouselSectionFields
  }
`;

export const sectionsFragment = gql`
  ${textSectionFragment}
  ${imageSectionFragment}
  ${eventRegistrationSectionFragment}
  ${videoSectionFragment}
  ${textImageSectionFragment}
  ${textVideoSectionFragment}
  ${ctaButtonsSectionFragment}
  ${accessibilitySectionFragment}
  ${imageSliderSectionFragment}
  ${faqSectionFragment}
  ${quoteSectionFragment}
  ${contentCarouselSectionFragment}
  ${languageFormSectionFragment}
  ${eventCarouselSectionFragment}
  ${newsletterSectionFragment}
  ${iframeSectionFragment}
  ${segmentationSectionFragment}
`;
