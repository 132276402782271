import {
  contentItem,
  eventItem,
  locationItem,
  mainCategoryItem,
  newsItem,
  routeItem,
  subCategoryItem,
  subSubCategoryItem,
  zigzagCampaignItem,
} from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

const SEARCH_QUERY = gql`
  query SEARCH_QUERY(
    $query: String
    $contentTypes: [String!]
    $limit: Int
    $skip: Int
    $locale: String
  ) {
    ContentItems(
      locale: $locale
      where: { _search: $query, _typename_any: $contentTypes }
      limit: $limit
      skip: $skip
      sort: changed_on_DESC
    ) {
      items {
        typename: __typename
        ... on LocationPage {
          ...locationItem
        }
        ... on EventPage {
          ...eventItem
        }
        ... on RoutePage {
          ...routeItem
        }
        ... on NewsPage {
          ...newsItem
        }
        ... on ContentPage {
          ...contentItem
        }
        ... on MainCategory {
          ...mainCategoryItem
        }
        ... on SubCategory {
          ...subCategoryItem
        }
        ... on SubSubCategory {
          ...subSubCategoryItem
        }
        ... on ZigzagCampaignPage {
          ...zigzagCampaignItem
        }
      }
      total
    }
  }
  ${locationItem}
  ${eventItem}
  ${routeItem}
  ${newsItem}
  ${contentItem}
  ${mainCategoryItem}
  ${subCategoryItem}
  ${subSubCategoryItem}
  ${zigzagCampaignItem}
`;

export default SEARCH_QUERY;
